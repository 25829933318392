import * as React from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { prettifyPhoneNumber } from '@kvika/string-utils';
import { useDispatch, useSelector } from 'react-redux';
import IndexHead from '../components/IndexHead';
import LoginScreenSlice from '../components/Slices/Login/LoginScreenSlice';
import LayoutWrapper from '../components/layout/LayoutWrapper';
import { LoginText, Navigation } from '../constants/Text';
import { getLoginModalText } from '../utils/Utils';
import { LoginType } from '../types/Types';
import { clearAnswers } from '../store/answer';
import { logout, selectSessionState } from '../store/session';
import { clearSession } from '../utils/AuthenticationStorage';
import { resetCompanyState } from '../store/companies';
import { resetEntityState } from '../store/entity';
import { clearErrorState } from '../store/error';

const StyledLoginContainer = styled.div`
  display: flex;
  height: 100%;
  h3 {
    font-size: 70px;
    line-height: 68px;
  }
`;

const Home = () => {
  const { loggedIn } = useSelector(selectSessionState);
  const [hasExchangeToken, setHasExchangeToken] = React.useState(false);
  const router = useRouter();
  const dispatch = useDispatch();

  const [phoneNumber, setPhoneNumber] = React.useState('');

  React.useEffect(() => {
    dispatch(clearAnswers());
    clearSession();
    dispatch(resetCompanyState());
    dispatch(resetEntityState());
    dispatch(clearErrorState());
    setHasExchangeToken(router.asPath.includes('phone=') && router.asPath.includes('token='));
    // logout user if he is logged in
    if (loggedIn) {
      dispatch(logout());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    !loggedIn && (
      <StyledLoginContainer>
        <IndexHead />
        <LayoutWrapper
          kardio={false}
          title={LoginText.Title}
          showBanner={false}
          showClearAnswers={false}
          hideTextHeader={hasExchangeToken}
        >
          <LoginScreenSlice
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            hasExchangeToken={hasExchangeToken}
            captions={{
              login_title: LoginText.LoginTitle,
              login_subtitle: LoginText.LoginSubtitle,
              tab_individual: LoginText.TabIndividual,
              tab_legal_entity: LoginText.TabLegalEntity,
              ssn_label: LoginText.SSNLabel,
              phone_number_label: LoginText.PhoneNumberLabel,
              invalid_phone_number_message: LoginText.InvalidPhoneNumberMessage,
              invalid_ssn_message: LoginText.InvalidSSNMessage,
              login_with_electronic_id: LoginText.LoginWithElectronicID,
              login_with_audkenni_app: LoginText.LoginWithAudkenniApp,
            }}
            modalFields={[
              {
                caption: LoginText.ElectronicIdInPhone,
                modal_text: getLoginModalText(prettifyPhoneNumber(phoneNumber)),
                key: LoginType.LOGIN_PHONE_EID,
                is_blocking: false,
              },
              {
                caption: LoginText.Title,
                modal_text: LoginText.NeedToOnboard,
                confirm_button_text: Navigation.Forward,
                key: LoginType.SIGNUP_REDIRECT,
                is_blocking: false,
              },
              {
                caption: LoginText.Title,
                modal_text: LoginText.NeedToFinishOnboarding,
                confirm_button_text: Navigation.Forward,
                key: LoginType.SIGNUP_REDIRECT,
                is_blocking: false,
              },
            ]}
          />
        </LayoutWrapper>
      </StyledLoginContainer>
    )
  );
};

export default Home;
